import { SvgIcon, SvgIconProps } from '@mui/material';

export function SuperAdminIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M4.0867 3.6538C4.4248 3.2392 4.9227 3 5.4472 3h13.1721c.532 0 1.0361.246 1.3742.6709l2.6008 3.2673c.5489.6896.5399 1.6871-.0214 2.366l-9.1387 11.0514c-.7063.8542-1.9816.86-2.6952.0123L1.4374 9.3189c-.5763-.6846-.584-1.7015-.018-2.3953l2.6673-3.2698Zm1.3605.5733c-.1749 0-.3408.0797-.4535.2179L2.3264 7.7148c-.1886.2313-.1861.5702.006.7984l9.3016 11.0491a.5806.5806 0 0 0 .8984-.0042L21.671 8.5067c.1871-.2263.1901-.5588.0072-.7887l-2.6008-3.2673c-.1127-.1416-.2808-.2236-.4581-.2236H5.4472Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M15.1212 11.5281C15.1206 13.487 13.6671 15 11.8546 15c-.7401 0-1.359-.1939-1.8678-.5283v.3901H8.0793v-8.765L9.9868 5v3.5979c.5088-.3322 1.1255-.5283 1.8678-.5283 1.8125 0 3.266 1.5286 3.2666 3.4585Zm-1.8811.0011c0-.9719-.7137-1.819-1.7027-1.819-.5616 0-1.1255.194-1.5506.7646v2.1244c.4251.584.989.778 1.5506.778.989 0 1.7027-.8627 1.7027-1.848Z"
          clipRule="evenodd"
        />
        <path
          fill="currentColor"
          d="M15.1212 11.5281v.0011-.0022.0011ZM17 14.0771c0 .5105-.4075.9229-.9119.9229-.5044 0-.9119-.4124-.9119-.9229 0-.5105.4075-.9228.9119-.9228.5044 0 .9119.4123.9119.9228ZM7.3568 14.4962h.0243l.1431.214.1432-.214h.0242v.3611h-.0264v-.3143l-.141.2073-.141-.2073v.3143h-.0264v-.3611ZM7.1234 14.523H7v-.0245h.2753v.0245h-.1255v.3366h-.0264v-.3366Z"
        />
      </svg>
    </SvgIcon>
  );
}
