export default {
  pages: {
    profile: {
      title: 'Profile',
      signOut: 'Sign out',
      signOutRedirectError: 'Failed redirecting to sign out',
      superAdmin: 'Super admin',
      organizationList: {
        selectOrganization: 'Select organization',
        search: 'Search',
        description:
          'Create a new organization or join an existing one. Open the app to book a desk!',
        errorTitle: 'Could not fetch organizations',
        errorText:
          'Something went wrong fetching your profile and organizations. Try signing out and signing back in again.',
        addOrganizationButton: {
          join: 'Join organization',
          create: 'Create organization',
        },
        organizationCard: {
          awaitingApproval: 'Awaiting approval from admin',
          dashboardButton: 'Admin dashboard',
          joinButton: 'Accept',
          declineButton: 'Decline',
          expired: 'Invitation has expired',
          alerts: {
            AcceptInvitationMutation: {
              success: 'Invite accepted',
              failure: 'Could not accept invite',
            },
            DeclineInvitationMutation: {
              success: 'Invite declined',
              failure: 'Could not decline invite',
            },
          },
        },
      },
      createOrganization: {
        title: 'Create organization',
        submitButton: 'Get started',
        signOut: 'Sign out',
        alreadyHaveOrg: 'Already have an organization?',
        joinOrganizationLink: 'Join an organization',
        name: {
          label: 'Organization name',
          helperText: 'The name of your organization.',
          errors: {
            required: 'Organization name required',
          },
        },
        domain: {
          helperText: 'The shortname used to join your organization',
          label: 'Organization shortname',
          errors: {
            default: 'Could not create organization',
            alreadyExists: 'Organization with shortname already exists',
            required: 'Organization shortname required',
            notAvailable: 'This organization shortname is not available',
            alphanumericValidation:
              'Organization shortname can only contain alphanumeric characters',
          },
        },
      },
      joinOrganization: {
        title: 'Join organization',
        domain: {
          label: 'Organization shortname',
          helperText: 'Get the shortname by asking an organization admin',
          errors: {
            required: 'Organization shortname required',
            alphanumericValidation:
              'Organization shortname can only contain alphanumeric characters',
            notFound: 'Organization not found',
            alreadyMember: 'You are already a member of this organization',
            banned: 'You are banned from this organization',
            pending: "You've already requested to join this organization",
          },
        },
        submitButton: 'Ask to join',
        missingOrg: "Don't have an organization?",
        createOrganizationLink: 'Create an organization',
        readyToGetToWork: 'Ready to get to work?',
        hiWithName: (name: string) => `Hi ${name}!`,
        hi: 'Hi!',
        alerts: {
          failure: 'Could not join organization',
        },
      },
    },
    invite: {
      invalidInviteUrlError: {
        title: 'Invalid invite URL',
        actions: {
          goToLanding: 'Go to landing',
        },
      },
      emailMismatchError: {
        title: 'Invitation email does not match',
        description: (profileEmail: string, inviteEmail: string) =>
          `You're currently signed in with ${profileEmail} however this invite was intended for ${inviteEmail}.`,
        buttons: {
          switchAccount: 'Switch account',
          myOrganizations: 'Go to your organizations',
        },
        signInRedirectError: 'Redirect to sign in failed',
      },
    },
    activate: {
      title: 'Select organization',
      description: 'Pick an organization for your Overview Screen.',
      notAllowed: 'Requires admin permission',
      button: 'Admin dashboard',
    },
    landing: {
      title: 'Biamp Workplace',
      subtitle:
        'Connect your Biamp devices into Biamp Workplace ecosystem and gain valuable insights.',
      signInOrRegister: 'Sign in or Register',
      myOrganizations: 'Go to your organizations',
      alerts: {
        signInRedirectFailed: 'Failed to redirect to sign in page',
      },
    },
    signInCallback: {
      error: {
        title: 'Failed to sign in',
        description: 'Something went wrong while signing in, please try again.',
        actions: {
          goToLanding: 'Go to landing',
        },
      },
    },
    signOutCallback: {
      error: {
        title: 'Failed to sign out',
        description: 'Something went wrong while signing out',
        actions: {
          goToLanding: 'Go to landing',
        },
      },
    },
    resource: {
      title: 'Download the Biamp Workplace app',
      subtitle:
        'In order to gain access to Workplace features, such as Booking, QR Check-in and others, download the Biamp Workplace App',
    },
    setup: {
      title: 'Welcome to Evoko Naso!',
      subtitle:
        "Thank you for choosing Evoko Naso, the smart solution for managing your office efficiently. Let's get started!",
      gettingStarted: {
        title: 'Getting Started',
        subtitle:
          'Kickstart your setup and explore our user manual for detailed instructions.',
        linkText: 'Get started',
      },
      faq: {
        title: 'Frequently Asked Questions',
        subtitle:
          "We've compiled a list of the common queries to help you navigate through uncertainties.",
        linkText: 'View FAQ',
      },
      guidesAndSupport: {
        title: 'Guides & Support',
        subtitle: 'Need more help? Our support team is here to assist you.',
        linkText: 'Contact Support',
      },
    },
    eula: {
      title: 'EULA',
      errorPage: {
        title: 'Something went wrong',
        description: 'Failed loading End User License Agreement',
      },
    },
  },
  components: {
    pageLogoHeader: {
      home: 'Home',
    },
  },
};
