export default {
  common: {
    close: 'Close',
    show: 'Show',
    reset: 'Reset',
    back: 'Back',
    error: (error?: string) => `Error: ${error || 'something went wrong'}`,
    yes: 'Yes',
    no: 'No',
    more: 'More',
  },
  enums: {
    deviceState: {
      UNPROVISIONED: 'Unprovisioned',
      PROVISIONED: 'Provisioned',
      ONLINE: 'Online',
      OFFLINE: 'Offline',
      MISSING: 'Missing',
    },
  },
  inputs: {
    deviceStateAutocomplete: {
      label: {
        one: 'Status',
        multiple: 'Status',
      },
    },
  },
  passwordTextField: {
    errors: {
      minLength: 'Password must contain at least 8 characters',
      lowerCase: 'Password must contain at least one lowercase letter',
      upperCase: 'Password must contain at least one uppercase letter',
      digit: 'Password must contain at least one digit',
      specialChar: 'Password must contain at least one special character',
    },
  },
  deviceStateTableCellContent: {
    lastSeen: (time: string) => `Last seen ${time}`,
    neverSeen: 'Never seen',
    awaitingStatus: 'Awaiting status',
    errors: 'Errors',
    noErrors: 'No errors',
    failed: 'Failed to load device errors',
  },
  DeviceFirmwareStatusTableCellContent: {
    runningVersion: (version: string) => `Running version: ${version}`,
    assignedVersion: (version: string) => `Assigned version: ${version}`,
    latestVersion: (version: string) => `Latest version: ${version}`,
    latestVersionWithChannel: (version: string, channelName: string) =>
      `Latest version: ${version} (${channelName})}`,
    upToDate: 'Up to date',
    pendingUpdate: 'Pending update',
    pendingDowngrade: 'Pending downgrade',
    outdated: 'Update available',
    aheadOfLatest: 'Ahead of latest',
    noChannel: 'No channel assigned',
    noLatestFirmware: 'No latest version',
    noAssignedFirmware: 'No version assigned',
    noRunningFirmware: "Can't find running version",
  },
  licenseTableStatusCellContent: {
    idle: 'Not activated',
    active: 'Active',
    expiresSoon: 'Expires soon',
    expired: 'Expired',
    revoked: 'Revoked',
  },
  signOutDialog: {
    title: 'Sign out',
    confirmationText: 'Are you sure you want to sign out?',
    cancel: 'Cancel',
    confirm: 'Sign out',
    redirectError: 'Failed redirecting to sign out',
  },
  table: {
    noResults: 'Nothing to show',
    somethingWentWrong: 'Oops! Something went wrong',
    unknownError: 'unknown error',
    sortedDesc: 'sorted descending',
    sortedAsc: 'sorted ascending',
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    select: (name: string) => `Select: ${name}`,
    unselect: (name: string) => `Unselect: ${name}`,
    search: 'Search',
    filters: 'Filters',
    clearAll: 'Clear all',
    columns: 'Columns',
    export: 'Export to CSV',
    selectColumnsLabel: 'Select columns to display',
  },
  errorPage: {
    message: 'Message',
    code: 'Code',
    sentryId: 'ID',
  },
  profileQueryerrorPage: {
    title: 'Failed to load',
    description: 'Something went wrong loading your profile',
  },
  signInRedirectErrorPage: {
    title: 'Redirect to sign in failed',
    description: 'Something went wrong while redirecting to the sign in page',
  },
};
