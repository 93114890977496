import { getSuperAdminUrl, useAuth } from '@evoko/api';
import {
  LogoutIcon,
  Page,
  SuperAdminIcon,
  useSnackbar,
} from '@evoko/components';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Outlet, Route, Routes } from 'react-router-dom';
import background from '../../assets/landing-page-background.jpg';
import { BiampBlock } from '../../components/BiampBlock';
import { useProfileQuery } from '../../hooks';
import locale from '../../locale';
import { CreateOrganization } from './components/CreateOrganization';
import { JoinOrganization } from './components/JoinOrganization';
import { OrganizationList } from './components/OrganizationList';

export const leftColumnBottomHeight = 2.4;

const LL = locale.pages.profile;

function ProfileLayout() {
  const { profile } = useProfileQuery();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const theme = useTheme();
  const now = new Date();

  return (
    <Page
      title={LL.title}
      component="main"
      minHeight="100%"
      display="flex"
      p={2}
      sx={{
        backgroundImage: { xs: 'none', sm: `url("${background}")` },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <BiampBlock>
        <Outlet />
        <Stack
          direction="row"
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          gap={2}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ background: theme.palette.common.white }}
              />
            }
            height={({ spacing }) => spacing(leftColumnBottomHeight)}
          >
            <LoadingButton
              color="primary"
              startIcon={<LogoutIcon aria-hidden={true} />}
              onClick={async () => {
                try {
                  await auth.signOutRedirect({ errorPolicy: 'throw' });
                } catch {
                  enqueueSnackbar({
                    message: LL.signOutRedirectError,
                    variant: 'error',
                  });
                }
              }}
              loading={auth.loading}
            >
              <Typography component="span" textTransform="none">
                {LL.signOut}
              </Typography>
            </LoadingButton>
            {profile?.superAdmin && (
              <Button
                color="primary"
                startIcon={<SuperAdminIcon aria-hidden={true} />}
                href={getSuperAdminUrl().href}
              >
                <Typography component="span" textTransform="none">
                  {LL.superAdmin}
                </Typography>
              </Button>
            )}
          </Stack>
          <Stack
            component="ul"
            direction="row"
            alignItems="baseline"
            gap={1}
            color="text.secondary"
            p={0}
            m={0}
            sx={{ listStyle: 'none' }}
          >
            <Typography component="li">
              <Link color="inherit" underline="hover" href="https://biamp.com">
                {now.getFullYear()} Biamp®
              </Link>
            </Typography>
            <Typography component="li">
              <Link
                color="inherit"
                underline="hover"
                href="https://meetevoko.com/privacy-policy/"
              >
                Privacy
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </BiampBlock>
    </Page>
  );
}

export default function ProfilePage() {
  return (
    <Routes>
      <Route element={<ProfileLayout />}>
        <Route index element={<OrganizationList />} />
        <Route path="create-organization" element={<CreateOrganization />} />
        <Route path="join-organization" element={<JoinOrganization />} />
      </Route>
    </Routes>
  );
}
