import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  BoxProps,
  Card,
  CardProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { Link } from 'react-router-dom';
import OrganizationLogo from '../OrganizationLogo';

type OrganizationCardProps = {
  logoUrl?: string;
  logoDimmed?: boolean;
  clickable?: boolean;
} & CardProps;

export function OrganizationCard({
  logoUrl,
  logoDimmed,
  children,
  clickable,
  ...cardProps
}: OrganizationCardProps) {
  return (
    <Card
      component={clickable && Link}
      sx={{
        minHeight: 88,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        py: 1,
        px: 2,
        background: 'inherit',
        border: '1px solid',
        borderColor: 'divider',
        cursor: clickable ? 'pointer' : 'default',
        textDecoration: 'none',
      }}
      {...cardProps}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        height="100%"
        width="100%"
      >
        <OrganizationLogo
          width={64}
          height={64}
          src={logoUrl}
          dimmed={logoDimmed}
        />
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          width="100%"
          minWidth={0}
          minHeight={64}
          justifyContent="center"
        >
          {children}
        </Box>
        {clickable && <ChevronRightIcon fontSize="large" />}
      </Box>
    </Card>
  );
}

export function OrganizationCardTitle(props: TypographyProps) {
  return <Typography variant="h3" component={'h2'} noWrap {...props} />;
}

export function OrganizationCardDescription(props: TypographyProps) {
  return <Typography variant="body2" fontWeight="bold" {...props} />;
}

export function OrganizationCardActions(props: BoxProps) {
  return <Box display="flex" gap={1} width="100%" mt="auto" {...props} />;
}
