import { RequireAuth, getPublicUrl, useAuth } from '@evoko/api';
import {
  ErrorPage,
  ErrorPageActions,
  ErrorPageDescription,
  ErrorPageTitle,
  ProfileQueryErrorPage,
  SignInRedirectErrorPage,
  useSnackbar,
} from '@evoko/components';
import { LoadingButton } from '@mui/lab';
import { Button, LinearProgress } from '@mui/material';
import {
  Navigate,
  Link as RouterLink,
  useSearchParams,
} from 'react-router-dom';
import { useProfileQuery } from '../../hooks';
import locale from '../../locale';

const LL = locale.pages.invite;

export default function InvitePage() {
  const inviteEmail = useSearchParams()[0].get('email');
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const profileQuery = useProfileQuery({ skip: !auth.authenticated });

  if (!inviteEmail) {
    return (
      <ErrorPage title={LL.invalidInviteUrlError.title}>
        <ErrorPageTitle>{LL.invalidInviteUrlError.title}</ErrorPageTitle>
        <ErrorPageActions>
          <Button
            variant="contained"
            color="primary"
            to="/"
            component={RouterLink}
          >
            {LL.invalidInviteUrlError.actions.goToLanding}
          </Button>
        </ErrorPageActions>
      </ErrorPage>
    );
  }

  if (!auth.authenticated) {
    return (
      <RequireAuth
        fallback={<LinearProgress />}
        errorElement={({ error }) => <SignInRedirectErrorPage error={error} />}
        returnTo={getPublicUrl('/organizations')}
        loginHint={inviteEmail}
      >
        {null}
      </RequireAuth>
    );
  }

  if (profileQuery.loading) {
    return <LinearProgress />;
  }

  if (profileQuery.error || !profileQuery.profile) {
    return <ProfileQueryErrorPage error={profileQuery.error} />;
  }

  if (inviteEmail.toLowerCase() !== profileQuery.profile.email.toLowerCase()) {
    return (
      <ErrorPage title={LL.emailMismatchError.title}>
        <ErrorPageTitle>{LL.emailMismatchError.title}</ErrorPageTitle>
        <ErrorPageDescription>
          {LL.emailMismatchError.description(
            profileQuery.profile.email,
            inviteEmail,
          )}
        </ErrorPageDescription>
        <ErrorPageActions>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={async () => {
              try {
                await auth.signInRedirect({
                  loginHint: inviteEmail,
                  returnTo: getPublicUrl('/organizations'),
                });
              } catch {
                enqueueSnackbar({
                  message: LL.emailMismatchError.signInRedirectError,
                  variant: 'error',
                });
              }
            }}
          >
            {LL.emailMismatchError.buttons.switchAccount}
          </LoadingButton>
          <Button
            variant="contained"
            color="primary"
            to="/organizations"
            component={RouterLink}
          >
            {LL.emailMismatchError.buttons.myOrganizations}
          </Button>
        </ErrorPageActions>
      </ErrorPage>
    );
  }

  return <Navigate to="/organizations" replace />;
}
