import { Role, getAdminUrl, hasRole } from '@evoko/api';
import {
  OrganizationCard,
  OrganizationCardDescription,
  OrganizationCardTitle,
} from '@evoko/components';
import {
  MembershipStatus,
  type MembershipFieldsFragment,
} from '../../../../../generated/graphql';
import locale from '../../../../../locale';

const LL = locale.pages.profile.organizationList.organizationCard;

type MembershipOrganizationCardProps = {
  membership: MembershipFieldsFragment;
};

export function MembershipOrganizationCard({
  membership,
}: MembershipOrganizationCardProps) {
  const isAdmin = hasRole(membership.role, Role.DeskAdmin);

  const { href } = getAdminUrl(membership.organization?.domain);

  const handleCardClick = () => {
    if (isAdmin) {
      window.location.href = href;
    }
  };

  return (
    <OrganizationCard
      key={membership.orgId}
      logoUrl={membership.organization?.logoUrl ?? undefined}
      logoDimmed={membership.status !== MembershipStatus.Active}
      onClick={handleCardClick}
      clickable={isAdmin}
    >
      <OrganizationCardTitle>
        {membership.organization?.name}
      </OrganizationCardTitle>
      {membership.status === MembershipStatus.Requested && (
        <OrganizationCardDescription color="warning.main">
          {LL.awaitingApproval}
        </OrganizationCardDescription>
      )}
    </OrganizationCard>
  );
}
