import { gql, useMutation } from '@apollo/client';
import { ErrorCode, getAdminUrl } from '@evoko/api';
import { Form } from '@evoko/components';
import { alphanumericRegex } from '@evoko/utils';
import { ChevronLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  Link as MuiLink,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import {
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
} from '../../../generated/graphql';
import locale from '../../../locale';

const M_CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      domain
    }
  }
`;

const LL = locale.pages.profile.createOrganization;

export function CreateOrganization() {
  const { enqueueSnackbar } = useSnackbar();
  const { getValues, setValue, handleSubmit, formState, control, setError } =
    useForm({
      mode: 'onChange',
      defaultValues: {
        name: '',
        domain: '',
      },
    });
  const { isValid, isSubmitting } = formState;

  const [createOrganization, { loading }] = useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(M_CREATE_ORGANIZATION, {
    onCompleted: ({ createOrganization: { domain } }) => {
      window.location.assign(getAdminUrl(domain));
    },
    onError: ({ graphQLErrors }) => {
      for (const { extensions } of graphQLErrors) {
        if (extensions?.code === ErrorCode.AlreadyExists) {
          setError('domain', { message: LL.domain.errors.alreadyExists });
          return;
        }
      }
      enqueueSnackbar(LL.domain.errors.default, { variant: 'error' });
    },
  });

  const onSubmit = handleSubmit(async (input) => {
    await createOrganization({ variables: { input } });
  });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Helmet>
        <title>{LL.title}</title>
      </Helmet>
      <Box>
        <IconButton
          component={Link}
          to=".."
          aria-label={locale.pages.profile.title}
        >
          <ChevronLeft fontSize="large" />
        </IconButton>
      </Box>
      <Typography variant="h1">{LL.title}</Typography>
      <Form onSubmit={onSubmit}>
        <Controller
          name="name"
          control={control}
          rules={{ required: LL.name.errors.required }}
          render={({
            field: { onChange, ...field },
            fieldState: { error, isTouched },
          }) => (
            <TextField
              {...field}
              label={LL.name.label}
              variant="outlined"
              margin="dense"
              fullWidth
              disabled={isSubmitting}
              error={!!error && isTouched}
              helperText={(isTouched && error?.message) || LL.name.helperText}
              onChange={(event) => {
                setValue(
                  'domain',
                  slugify(event.target.value, {
                    replacement: '',
                    strict: true,
                    lower: true,
                  }),
                  { shouldValidate: true },
                );
                onChange(event);
              }}
            />
          )}
        />
        <Controller
          name="domain"
          control={control}
          rules={{
            required: LL.domain.errors.required,
            pattern: {
              value: alphanumericRegex,
              message: LL.domain.errors.alphanumericValidation,
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label={LL.domain.label}
              variant="outlined"
              margin="dense"
              fullWidth
              disabled={isSubmitting}
              error={!!error}
              helperText={error?.message || LL.domain.helperText}
              InputLabelProps={{
                shrink: getValues('domain') ? true : undefined,
              }}
            />
          )}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          loading={loading}
          disabled={!isValid}
        >
          {LL.submitButton}
        </LoadingButton>
      </Form>
      <Box textAlign="center">
        <Typography component="small" variant="body2" color="text.secondary">
          {LL.alreadyHaveOrg}{' '}
          <MuiLink component={Link} to="../join-organization" color="inherit">
            {LL.joinOrganizationLink}
          </MuiLink>
        </Typography>
      </Box>
    </Box>
  );
}
