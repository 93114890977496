import { SvgIcon, SvgIconProps } from '@mui/material';

export function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.25 16.93v1.52a3.05 3.05 0 0 0 3.05 3.05h7.65A3.05 3.05 0 0 0 21 18.45V5.05A3.05 3.05 0 0 0 17.95 2H10.3a3.05 3.05 0 0 0-3.05 3.05v1.52a.76.76 0 1 0 1.53 0V5.05a1.53 1.53 0 0 1 1.52-1.53h7.65a1.52 1.52 0 0 1 1.53 1.53v13.4a1.52 1.52 0 0 1-1.53 1.52H10.3a1.53 1.53 0 0 1-1.52-1.52v-1.52a.76.76 0 1 0-1.53 0Zm-5.19-6.5 1.7-1.69a.76.76 0 1 1 1.08 1.08l-1.21 1.2h9.15a.76.76 0 1 1 0 1.53H3.63l1.21 1.21a.76.76 0 1 1-1.07 1.08l-1.71-1.7a1.9 1.9 0 0 1 0-2.7Z" />
      </svg>
    </SvgIcon>
  );
}
