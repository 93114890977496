import { SvgIcon, SvgIconProps } from '@mui/material';

export function JoinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.74936 17.526L7.74936 19.0487C7.74904 19.8572 8.06976 20.6328 8.64103 21.205C9.21231 21.7772 9.98739 22.0991 10.7959 22.1001L18.4503 22.1001C19.2584 22.0991 20.0331 21.7775 20.6043 21.2059C21.1755 20.6342 21.4965 19.8592 21.4969 19.0511L21.4969 5.64667C21.4959 4.83896 21.1746 4.06461 20.6035 3.49347C20.0323 2.92234 19.258 2.60105 18.4503 2.6001L10.7959 2.6001C9.98822 2.60105 9.21387 2.92234 8.64273 3.49347C8.0716 4.06461 7.75031 4.83896 7.74936 5.64667L7.74936 7.17417C7.74528 7.2767 7.76195 7.37899 7.79838 7.47492C7.8348 7.57085 7.89022 7.65843 7.96132 7.73241C8.03242 7.80639 8.11773 7.86525 8.21213 7.90546C8.30654 7.94567 8.40809 7.96639 8.5107 7.96639C8.61331 7.96639 8.71486 7.94567 8.80926 7.90546C8.90367 7.86525 8.98898 7.80639 9.06008 7.73241C9.13118 7.65843 9.1866 7.57085 9.22302 7.47492C9.25945 7.37899 9.27612 7.2767 9.27204 7.17417L9.27204 5.64667C9.27236 5.24261 9.43302 4.85519 9.71873 4.56947C10.0044 4.28376 10.3919 4.1231 10.7959 4.12278L18.4503 4.12278C18.8543 4.1231 19.2418 4.28376 19.5275 4.56947C19.8132 4.85519 19.9739 5.24261 19.9742 5.64667L19.9742 19.0511C19.9735 19.4548 19.8129 19.8417 19.5275 20.1271C19.2421 20.4125 18.8551 20.5732 18.4515 20.5738L10.7959 20.5738C10.3923 20.5732 10.0054 20.4125 9.71993 20.1271C9.43451 19.8417 9.27388 19.4548 9.27325 19.0511L9.27325 17.526C9.27732 17.4235 9.26065 17.3212 9.22423 17.2253C9.1878 17.1293 9.13238 17.0418 9.06128 16.9678C8.99018 16.8938 8.90487 16.8349 8.81047 16.7947C8.71606 16.7545 8.61451 16.7338 8.5119 16.7338C8.40929 16.7338 8.30774 16.7545 8.21334 16.7947C8.11894 16.8349 8.03363 16.8938 7.96253 16.9678C7.89143 17.0418 7.836 17.1293 7.79958 17.2253C7.76316 17.3212 7.74528 17.4235 7.74936 17.526Z" />
        <path d="M11.8043 15.4387L13.51 13.7415V13.7391C13.8664 13.3817 14.0666 12.8975 14.0666 12.3928C14.0666 11.888 13.8664 11.4038 13.51 11.0464L11.8043 9.34078C11.7342 9.26759 11.6501 9.20914 11.557 9.16886C11.4639 9.12859 11.3638 9.10729 11.2624 9.10623C11.161 9.10517 11.0604 9.12435 10.9665 9.16267C10.8726 9.20098 10.7873 9.25765 10.7156 9.32936C10.6439 9.40106 10.5872 9.48636 10.5489 9.58025C10.5106 9.67414 10.4914 9.77473 10.4925 9.87613C10.4935 9.97753 10.5148 10.0777 10.5551 10.1708C10.5954 10.2638 10.6538 10.3479 10.727 10.4181L11.9404 11.629H2.79222C2.68969 11.6249 2.58739 11.6416 2.49147 11.678C2.39554 11.7145 2.30796 11.7699 2.23397 11.841C2.15999 11.9121 2.10113 11.9974 2.06092 12.0918C2.02072 12.1862 1.99999 12.2878 1.99999 12.3904C1.99999 12.493 2.02072 12.5945 2.06092 12.6889C2.10113 12.7833 2.15999 12.8686 2.23397 12.9397C2.30796 13.0108 2.39554 13.0663 2.49147 13.1027C2.58739 13.1391 2.68969 13.1558 2.79222 13.1517H11.9404L10.727 14.3614C10.6538 14.4316 10.5954 14.5157 10.5551 14.6088C10.5148 14.7018 10.4935 14.802 10.4925 14.9034C10.4914 15.0048 10.5106 15.1054 10.5489 15.1993C10.5872 15.2932 10.6439 15.3785 10.7156 15.4502C10.7873 15.5219 10.8726 15.5785 10.9665 15.6169C11.0604 15.6552 11.161 15.6744 11.2624 15.6733C11.3638 15.6722 11.4639 15.6509 11.557 15.6107C11.6501 15.5704 11.7342 15.5119 11.8043 15.4387Z" />
      </svg>
    </SvgIcon>
  );
}
