import { JoinIcon, useDebounce } from '@evoko/components';
import { Search as SearchIcon } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useProfileQuery } from '../../../../hooks';
import locale from '../../../../locale';
import { InvitationOrganizationCard } from './components/InvitationOrganizationCard';
import { MembershipOrganizationCard } from './components/MembershipOrganizationCard';

const LL = locale.pages.profile.organizationList;

export function OrganizationList() {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { profile, loading, error } = useProfileQuery({
    fetchPolicy: 'cache-and-network',
  });

  const organizations = useMemo(
    () =>
      profile
        ? [...profile.memberships, ...profile.invitations].sort((a, b) =>
            (a.organization?.name ?? '').localeCompare(
              b.organization?.name ?? '',
            ),
          )
        : [],
    [profile],
  );

  const filteredOrganizations = useMemo(() => {
    if (!debouncedSearchTerm) return organizations;
    return organizations.filter((org) =>
      [org.organization?.name, org.organization?.domain]
        .filter(Boolean)
        .some((field) =>
          field?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()),
        ),
    );
  }, [debouncedSearchTerm, organizations]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  if (loading && !profile) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (error || !profile) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <Typography variant="h1" color="text.sidebar">
          {LL.errorTitle}
        </Typography>
        <Typography variant="body2" color="textSecondary" mt={2}>
          {LL.errorText}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={2} height={600}>
      <Box minHeight={430}>
        <Typography
          component="h1"
          variant="h2"
          color="text.sidebar"
          sx={{ overflowWrap: 'break-word' }}
        >
          {LL.selectOrganization}
        </Typography>
        {!organizations.length ? (
          <Typography variant="body2" color="text.secondary">
            {LL.description}
          </Typography>
        ) : (
          <Stack spacing={2} sx={{ maxHeight: 400, overflowY: 'auto' }} pt={1}>
            <TextField
              variant="outlined"
              label={LL.search}
              type="search"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Stack spacing={2} sx={{ maxHeight: 400, overflowY: 'auto' }}>
              {filteredOrganizations.map((data) => {
                switch (data.__typename) {
                  case 'Membership': {
                    return (
                      <MembershipOrganizationCard
                        key={data.orgId}
                        membership={data}
                      />
                    );
                  }
                  case 'Invitation': {
                    return (
                      <InvitationOrganizationCard
                        key={data.id}
                        invitation={data}
                        verified={profile.verified}
                      />
                    );
                  }
                }
              })}
            </Stack>
          </Stack>
        )}
      </Box>
      <Box>
        <Stack direction="row" gap={1}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            startIcon={<JoinIcon />}
            component={Link}
            to="join-organization"
          >
            {LL.addOrganizationButton.join}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<Add />}
            component={Link}
            to="create-organization"
          >
            {LL.addOrganizationButton.create}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
